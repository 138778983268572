import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './search.css';
import en from '../../locales/en.json';
import hu from '../../locales/hu.json';
import de from '../../locales/de.json';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import Mark from 'mark.js';

const locales = { en, hu, de };

// Step 1: Flatten the locales
const flattenLocales = (locales) => {
    const result = [];
    for (const [locale, data] of Object.entries(locales)) {
        for (const [key, value] of Object.entries(data)) {
            result.push({ key, value, locale });
        }
    }
    return result;
};

// Step 3: Display the results
const Results = ({ results, clearSearch }) => {
    const handleClick = () => {
        clearSearch();
    }
    return (
        <ul className='search-bar-ul'>
            {results.map(({ service, url, locale, classKey, matchingText }) => (
            <li className='search-bar-li' key={`${locale}-${service}-${url}`}>
                <Link className='search-bar-a' to={`${url}#${classKey}`} onClick={() => handleClick(locale)}>{matchingText}</Link>
            </li>
        ))}
        </ul>
    );
};

// Step 4: The search component
const Search = () => {
    const [term, setTerm] = useState('');
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [results, setResults] = useState([]);
    const { t, i18n } = useTranslation(); 
    const searchBarRef = useRef();
    const searchInputRef = React.useRef(null);
    const navigate = useNavigate();

    // Inside your Search component
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            setTimeout(() => {
                const element = document.getElementById(hash.substring(1));
                if (element) element.scrollIntoView();
            }, 0);
        }
    }, [location]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
                setIsSearchOpen(false);
                clearSearch();
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const clearSearch = () => {
        // setTerm('');
        setIsSearchOpen(false);
        setResults([]);
    };

    // Step 2: Search function
    const search = (term) => {
        if (term.trim() === '') {
            setResults([]);
            return;
        }

        const fL = flattenLocales(locales);
        const lowerCaseTerm = term.toLowerCase();
        const results = fL.filter(({ value, locale }) => {
            // Convert object values to an array of strings and check if term is included
            return Object.values(value).join(' ').toLowerCase().includes(lowerCaseTerm) && locale === i18n.language;
        })
        .map(({ key, locale, value }) => {
            const url = `${key}`;
            const classKey = Object.keys(value).find(key => value[key].toLowerCase().includes(lowerCaseTerm));
            const matchingText = value[classKey];
            return { service: key, url, locale, classKey, matchingText };
        });
        setResults(results);
            
    };

    useEffect(() => {
        if (term.trim() === '') {
            return;
        }
        highlightTerm();
    }, [results, term]);

    

    useEffect(() => {
        const clearSearchBar = () => {
            setIsSearchOpen(false);
            setResults([]);
        };
    
        window.addEventListener('clearSearchBar', clearSearchBar);
    
        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('clearSearchBar', clearSearchBar);
        };
    }, []);

    const highlightTerm = () => {
        const contextElement = document.querySelector('.service-subsite-content');
        const customerHelpElement = document.querySelector('.customer-help-container');
        if (contextElement) {
            setTimeout(() => {
                const contextElement = document.querySelector('.service-subsite-content');
                const customerHelpContext = document.querySelector('.customer-help-container');
                const instance = new Mark(contextElement);
                const instance2 = new Mark(customerHelpContext);
                instance.unmark(); // Remove previous highlights
                instance2.unmark(); // Remove previous highlights
                instance.mark(term); // Highlight new search term
                instance2.mark(term); // Highlight new search term
            }, 50); // Adjust the delay as needed
        }
    };

    const handleInputChange = (e) => {
        const newTerm = e.target.value;
        setTerm(newTerm);
        if (newTerm.length >= 3) {
            search(newTerm);
        }
        else {
            setResults([]);
        }
    };

    const handleIconClick = () => {
        setIsSearchOpen(true);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && results.length > 0) {
            e.preventDefault(); // Prevent the default action
            highlightTerm(); // Highlight the term
            // Navigate to the first search result after a delay
            setTimeout(() => {
                navigate(results[0].url);
                document.body.dispatchEvent(new MouseEvent('mousedown'));
                if (typeof TouchEvent !== 'undefined') {
                    document.body.dispatchEvent(new TouchEvent('touchstart'));
                }
                clearSearch();
            }, 50); // Adjust the delay as needed
        }
    };

    return (
        <div className='search-bar-container' ref={searchBarRef}>
            {isSearchOpen ? (
                <input ref={searchInputRef} id="main-search-bar" type="text" value={term} onChange={handleInputChange} onKeyDown={handleKeyPress} className='search-bar-input' placeholder={t('search_placeholder')}/>
            ) : (
                <FaSearch onClick={handleIconClick} />
            )}
            <Results results={results} clearSearch={clearSearch}/>
        </div>
    );
};

export default Search;