import { Subsite } from '../subsiteStructure.js';
import '../services.css'; // Import the CSS

function RPA() {
    const url = '/automation/rpa';
    const prefix = 'rpa';

    return (
        <Subsite url={url} prefix={prefix} />
    )
}

export default RPA;