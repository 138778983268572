import { Subsite } from '../subsiteStructure.js';
import '../services.css'; // Import the CSSs

function Monitoring() {
    const url = '/operations/monitoring';
    const prefix = 'monitoring';

    return (
        <Subsite url={url} prefix={prefix} />
    )
}

export default Monitoring;