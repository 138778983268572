import React from 'react';
import './header.css'; // Import the CSS
import Logo from '../logo/logo.js';
import HomePageNav from '../homePageNav/homePageNav.js';
import SmallNav from '../smallNav/smallNav.js';
import SidebarNav from '../sidebarNav/sidebarNav.js';
import LanguageSelector from '../languageSelector/languageSelector.js';
import Search from '../search/search.js';

function Header() {
    return (
        <>
        <div className="main-header">
            <Logo />
            <HomePageNav />
            <LanguageSelector />
            <Search />
            <SmallNav />
            <SidebarNav />
        </div>
        </>
    );
    }

export default Header;