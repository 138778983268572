import { Subsite } from '../subsiteStructure.js';
import '../services.css'; // Import the CSS

function ERP() {
    const url = '/automation/erp';
    const prefix = 'erp';

    return (
        <Subsite url={url} prefix={prefix} />
    )
}

export default ERP;