import React from "react";
import "./slogan.css";

function Slogan() {
    return (
        <>
        <div className="slogan-text">
            {Array.from("Leader of the IT Innovation Pack!").map((letter, index) => (
                <span 
                    key={index} 
                    className={`slogan-letter-${index}`}
                    style={{ animationDelay: `${index * 0.1}s` }}  // Add delay here
                >
                    {letter}
                </span>
            ))}
        </div>
        </>
    );
};

export default Slogan;