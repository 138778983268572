import { Subsite } from '../subsiteStructure.js';
import '../services.css'; // Import the CSS

function IoT() {
    const url = '/automation/iot';
    const prefix = 'iot';

    return (
        <Subsite url={url} prefix={prefix} />
    )
}

export default IoT;