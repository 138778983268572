import React, { useState } from 'react';
import HomePageNav from '../homePageNav/homePageNav.js';
import NavExit from '../navExit/navExit.js';
import LanguageSelecor from '../languageSelector/languageSelector.js';
import './sidebarNav.css'

function SidebarNav() {
    const [activeMenu, setActiveMenu] = useState(null);
    
    return (
        <div className="sidebar-nav">
            <HomePageNav activeMenu={ activeMenu } setActiveMenu={ setActiveMenu } />
            <NavExit setActiveMenu={ setActiveMenu } />
        </div>
    )
}

export default SidebarNav;