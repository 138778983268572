import React from 'react';
import { useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getQaKeys, SubsiteContentGenerator } from '../subsiteContentGenerator.js';
import './services.css';

function UseSubsite(url, prefix) {
    const match = useMatch(url);
    const { i18n } = useTranslation();

    const qaKeys = getQaKeys(i18n, url, prefix);
    return { match, qaKeys };
}

export function Subsite({ url, prefix }) {
    const { match, qaKeys } = UseSubsite(url, prefix);
    
    return (
        <>
            {match && (
                <div className="service-subsite-content">
                    {qaKeys.map((key) => (
                        <SubsiteContentGenerator key={key} qaKey={key} url={url} prefix={prefix} />
                    ))}
                </div>
            )}
        </>
    );
}