import React from 'react';
import { Outlet } from 'react-router-dom';
import BackButton from '../../../components/backbtn/BackButton.js';
import '../services.css'; // Import the CSS

const Cloud = () => {
    return (
        <>
            <BackButton />
            <Outlet />
        </>
    );
}

export default Cloud;