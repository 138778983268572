import React from 'react';
import { useTranslation } from 'react-i18next';


function CompanyInfo() {

    const { t } = useTranslation();

    return (
        <div className="company-footer-info-container">
            <p className="company-footer-title">{t('company_footer_title')}</p>
            <span className="company-footer-info company-footer-info-name"><b>Tech Wolf IT Kft.</b></span><br />
            <span className="company-footer-info">1223 Budapest</span><br />
            <span className="company-footer-info">Nagytétényi út 180-196.</span><br />
            <span className="company-footer-info">E-mail: <a href="mailto:info@techwolf-it.com">info@techwolf-it.com</a></span><br />
            <span className="company-footer-info">{t('company_info_phone')}: <a href="tel:+36301790339">+36 30 179 0339</a></span><br />
            <span className="company-footer-info">{t('company_info_vat_id')}: 32480779-2-43</span><br />
            <span className="company-footer-info">{t('company_info_reg_id')}: 01-09-426415</span><br />
        </div>
    );
}

export default CompanyInfo;