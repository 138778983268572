import { Subsite } from '../subsiteStructure.js';
import '../services.css'; // Import the CSSs

function ITSec() {
    const url = '/security/itsec';
    const prefix = 'sec_consulting';

    return (
        <Subsite url={url} prefix={prefix} />
    )
}

export default ITSec;
