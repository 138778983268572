import { Subsite } from '../subsiteStructure';
import '../services.css'; // Import the CSSs

function HWVirt() {
    const url = '/operations/hwvirt';
    const prefix = 'hw_virt';

    return (
        <Subsite url={url} prefix={prefix} />
    )
}

export default HWVirt;