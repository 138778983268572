import { useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './backbutton.css';
import { useTranslation } from "react-i18next";
import Mark from 'mark.js';

function BackButton() {

    let navigate = useNavigate();
    let location = useLocation();
    const searchInputRef = useRef(null);

    const { t } = useTranslation();

    const goBack = () => {
        const markInstance = new Mark(document.querySelector('.service-subsite-content'));
        const markInstance2 = new Mark(document.querySelector('.customer-help-content'));
        markInstance.unmark();
        markInstance2.unmark();
        if (searchInputRef.current) {
            searchInputRef.current.value = '';
        }
        navigate('/');
    };

    return(
        <button onClick={goBack} className="subsite-back-button">&#x25c0; <span className="subsite-back-text">{t('back_button')}</span></button>
    )
    
}

export default BackButton;