import { Subsite } from '../subsiteStructure';
import '../services.css'; // Import the CSS

function Networking() {
    const url = '/network/networking';
    const prefix = 'network_architecture';

    return (
        <Subsite url={url} prefix={prefix} />
    )

}

export default Networking;