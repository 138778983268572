import { Subsite } from '../subsiteStructure.js';
import '../services.css'; // Import the CSSs

function Microsoft365() {
    const url = '/cloud/microsoft365';
    const prefix = 'microsoft';

    return (
        <Subsite url={url} prefix={prefix} />
    )

}

export default Microsoft365;