import { Subsite } from '../subsiteStructure.js';
import '../services.css'; // Import the CSSs

function Audit() {
    const url = '/security/audit';
    const prefix = 'audit';

    return (
        <Subsite url={url} prefix={prefix} />
    )
}

export default Audit;
