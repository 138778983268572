import { Subsite } from '../subsiteStructure.js';
import '../services.css'; // Import the CSS

function PythonSoftware() {
    const url = '/software/python';
    const prefix = 'python';
    
    return (
        <Subsite url={url} prefix={prefix} />
    )
}

export default PythonSoftware;