import React from 'react';
import HomePageNav from '../homePageNav/homePageNav';
import './homeWolf.css'; // Import the CSS
import Slogan from '../slogan/slogan.js';

function HomeWolf() {
  return (
    <div className="home-wolf">
        {/* <HomePageNav /> */}
        <img src={process.env.PUBLIC_URL + "./wolf.jpg"} alt="wolf" />
        <Slogan />
    </div>
    );
}

export default HomeWolf;