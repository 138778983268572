// App.js
// Main component of the application, contains the routing for the different subsites
import React, { Suspense } from 'react';
import './i18n.js';
import Header from './components/header/header.js';
import Footer from './components/footer/footer.js';
import GlobalStyle from './GlobalStyle.js';
import HomeWolf from './components/homeWolf/homeWolf.js';
import './App.css';
import './MediaQuery.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Network from './subsites/services/network/Network.js';
import Cloud from './subsites/services/cloud/Cloud.js';
import Operations from './subsites/services/operations/Operations.js';
import Software from './subsites/services/software/Software.js';
import Automation from './subsites/services/automation/Automation.js';
import Security from './subsites/services/security/Security.js';
import Microsoft365 from './subsites/services/cloud/Microsoft365.js';
import Migration from './subsites/services/cloud/Migration.js';
import Consultation from './subsites/services/cloud/Consultation.js';
import Networking from './subsites/services/network/Networking.js';
import VPN from './subsites/services/network/VPN.js';
import Firewall from './subsites/services/network/Firewall.js';
import ITOps from './subsites/services/operations/ITOps.js';
import HWVirt from './subsites/services/operations/HardwareVirt.js';
import Monitoring from './subsites/services/operations/Monitoring.js';
import PythonSoftware from './subsites/services/software/Python.js';
import CSharpSoftware from './subsites/services/software/CSharp.js';
import WebDev from './subsites/services/software/Webdev.js';
import Scripting from './subsites/services/automation/Scripting.js';
import RPA from './subsites/services/automation/RPA.js';
import IoT from './subsites/services/automation/IoT.js';
import ERP from './subsites/services/automation/ERP.js';
import ITSec from './subsites/services/security/ITSec.js';
import Audit from './subsites/services/security/Audit.js';
import Pentest from './subsites/services/security/Pentest.js';

function App() {
  return (
    <>
    <Suspense fallback={<div>Loading...</div>}>
      <GlobalStyle />
        <div className="page-container">
          <Router>
            <Header />
              <Routes>
                <Route path="/" element={<HomeWolf />} />
                <Route path="/cloud" element={<Cloud />}>
                  <Route path="/cloud/microsoft365" element={<Microsoft365 />}/>
                  <Route path="/cloud/migration" element={<Migration />}/>
                  <Route path="/cloud/consulting" element={<Consultation />}/>
                </Route>
                <Route path="/network" element={<Network />}>
                  <Route path="/network/networking" element={<Networking />}/>
                  <Route path="/network/firewall" element={<Firewall />}/>
                  <Route path="/network/vpn" element={<VPN />}/>
                </Route>
                <Route path="/operations" element={<Operations />}>
                  <Route path="/operations/itops" element={<ITOps />} />
                  <Route path="/operations/hwvirt" element={<HWVirt />} />
                  <Route path="/operations/monitoring" element={<Monitoring />} />
                </Route>
                <Route path="/software" element={<Software />}>
                  <Route path="/software/python" element={<PythonSoftware />} />
                  <Route path="/software/csharp" element={<CSharpSoftware />} />
                  <Route path="/software/webdev" element={<WebDev />} />
                </Route>
                <Route path="/automation" element={<Automation />}>
                  <Route path="/automation/scripting" element={<Scripting />} />
                  <Route path="/automation/rpa" element={<RPA />} />
                  <Route path="/automation/iot" element={<IoT />} />
                  <Route path="/automation/erp" element={<ERP />} />
                </Route>
                <Route path="/security" element={<Security />}>
                  <Route path="/security/itsec" element={<ITSec />} />
                  <Route path="/security/audit" element={<Audit />} />
                  <Route path="/security/pentest" element={<Pentest />} />
                </Route>
              </Routes>
              <Footer />
          </Router>
        </div>
    </Suspense>
    </>
  );
}

export default App;