import React from 'react';
import './navExit.css'

export function clickMethods(setActiveMenu) {
    const handleClick = () => {
        const nav = document.querySelector('.sidebar-nav');
        nav.classList.toggle('active');
    };
    const deactivateMenu = () => {
        setActiveMenu(null);
    };
    if (window.innerWidth <= 800) {
        handleClick();
        deactivateMenu();
    }
}

function NavExit({ setActiveMenu }) {
    return (
        <i className="nav-exit" onClick={() => clickMethods(setActiveMenu)}>&#10005;</i>
    )
}


export default NavExit;