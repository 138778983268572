import { Subsite } from '../subsiteStructure.js';
import '../services.css'; // Import the CSS

function Consultation() {
    const url = '/cloud/consulting';
    const prefix = 'cloud_consulting';

    return (
        <Subsite url={url} prefix={prefix} />
    )

}

export default Consultation;