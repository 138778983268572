import './footer.css';
import CompanyInfo from '../companyInfo/CompanyInfo.js';
import ContactForm from '../contactForm/contactForm.js';
import CustomerHelp from '../customerHelp/customerHelp.js';

function Footer() {
    return (
        <div className="footer">
            <div className="company-footer">
                <CompanyInfo />
                {/* <div className="social-footer">
                <a href="https://www.facebook.com" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/fb.png"} alt="Facebook" /></a><br/>
                <a href="https://www.linkedin.com/company/102216095" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/lin.png"} alt="LinkedIn" /></a><br/>
            </div> */}
            </div>
            <CustomerHelp />
            {/* <div className="contact-footer">
                <ContactForm />
            </div> */}

        </div>
    );
}

export default Footer;