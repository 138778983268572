import { Subsite } from '../subsiteStructure.js';
import '../services.css'; // Import the CSS

function Scripting() {
    const url = '/automation/scripting';
    const prefix = 'scripting';
    return (
        <Subsite url={url} prefix={prefix} />
    )

}

export default Scripting;

