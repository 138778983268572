import { Subsite } from '../subsiteStructure';
import '../services.css'; // Import the CSS

function VPN() {
    const url = '/network/vpn';
    const prefix = 'vpn';

    return (
        <Subsite url={url} prefix={prefix} />
    )
}

export default VPN;