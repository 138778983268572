import { Subsite } from '../subsiteStructure.js';
import '../services.css'; // Import the CSSs

function Pentest() {
    const url = '/security/pentest';
    const prefix = 'pen_test';
    
    return (
        <Subsite url={url} prefix={prefix} />
    )
}

export default Pentest;
