import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./locales/en.json";
import huTranslation from "./locales/hu.json";
import deTranslation from "./locales/de.json";

const supportedLanguages = ['en', 'hu', 'de'];

const browserLanguage = navigator.language.split('-')[0]; // Get the language part of the BCP 47 tag
const userLanguage = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      hu: {
        translation: huTranslation
      },
      de: {
        translation: deTranslation
      }
    },
    lng: localStorage.getItem('i18nextLng') || userLanguage, // moved outside of resources
    fallbackLng: "en", // moved outside of resources
    interpolation: {
      escapeValue: false
    }
  });

i18n.on('languageChanged', function(lng) {
  localStorage.setItem('i18nextLng', lng);
});

export default i18n;