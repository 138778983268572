import React from 'react';
import { useTranslation } from 'react-i18next';
import './languageSelector.css';

function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="language-selector">
      <img className="language-selector-flag" src={process.env.PUBLIC_URL + "/hu.svg"} alt="Magyar" onClick={() => changeLanguage({ target: { value: 'hu' } })} />
      <img className="language-selector-flag" src={process.env.PUBLIC_URL + "/gb.svg"} alt="English" onClick={() => changeLanguage({ target: { value: 'en' } })} />
      <img className="language-selector-flag" src={process.env.PUBLIC_URL + "/de.svg"} alt="Deutsch" onClick={() => changeLanguage({ target: { value: 'de' } })} />
    </div>
  );
}

export default LanguageSelector;