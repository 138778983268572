import './smallnav.css'

function SmallNav() {
    const handleClick = () => {
        const nav = document.querySelector('.sidebar-nav');
        nav.classList.toggle('active');
    };

    return (
        <>
            <i className="smallnav-icon" onClick={handleClick}></i>
        </>
    )
}

export default SmallNav;