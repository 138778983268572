import React from "react";
import "./customerHelp.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getQaKeys, SubsiteContentGenerator } from "../../subsites/subsiteContentGenerator";

function CustomerHelp() {
    const { t, i18n } = useTranslation();

    const qaKeys = getQaKeys(i18n, "/", 'customer_help');

    return (
        <div className="customer-help-container">
            <p className="customer-help-title">{t('customer_help_title')}</p>
            <div className="customer-help-content">
                {qaKeys.map((key) => (
                    <SubsiteContentGenerator key={key} qaKey={key} url="/" prefix="customer_help" />
                ))}
            </div>
        </div>
    );
}

export default CustomerHelp;
            