import { Subsite } from '../subsiteStructure.js';
import '../services.css'; // Import the CSSs

function CSharpSoftware() {
    const url = '/software/csharp';
    const prefix = 'csharp';
    return (
        <Subsite url={url} prefix={prefix} />
    )
}

export default CSharpSoftware;