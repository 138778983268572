import { Subsite } from '../subsiteStructure.js';
import '../services.css'; // Import the CSS

function Firewall() {
    const url = '/network/firewall';
    const prefix = 'firewall';

    return (
        <Subsite url={url} prefix={prefix} />
    )

}

export default Firewall;