import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export function getQaKeys(i18n, url, prefix) {
  const resources = i18n.getResourceBundle(i18n.language, 'translation');
  const urlParts = urlExtract(url);
  let currentResource = resources;
  // Go through the objects in resources and find a match
  for (let cR in currentResource) {
    if (cR === urlParts) {
      currentResource = currentResource[cR];
      break;
    }
  }

  // Pass the matched object to getKeysFromNestedObject
  return getKeysFromNestedObject(currentResource, prefix);
}

function getKeysFromNestedObject(obj, prefix) {
  let keys = [];
  for (let key in obj) {
      if (typeof obj[key] === 'object') {
          keys = keys.concat(getKeysFromNestedObject(obj[key], `${prefix}_${key}`));
      } else if (key.startsWith(`${prefix}_q`) || key.startsWith(`${prefix}_a`)) {
          keys.push(key);
      }
  }
  return keys;
}

function urlExtract(url) {
  if (url.startsWith('/')) {
    return url.substring(1); // split the url and remove empty parts
  } else {
    return url
  }
}

export function SubsiteContentGenerator({ qaKey, url, prefix }) {
  const { t, i18n, ready } = useTranslation();
  let urlParts = urlExtract(url)+"."+qaKey;

  // Create a state to hold the subsite content
  const [subsiteContent, setSubsiteContent] = useState('');

  // Update the subsite content whenever the qaKey, url, or prefix prop changes
  useEffect(() => {
      if (qaKey.startsWith('customer_help')) {
          setSubsiteContent(t(qaKey));
      } else {
          setSubsiteContent(t(urlParts));
      }
  }, [qaKey, url, prefix, ready, i18n.language]);

  // Dispatch the subsiteLoaded event after the subsite content has been updated and the translation is ready
  useEffect(() => {
      if (ready) {
          window.dispatchEvent(new Event('subsiteLoaded'));
      }
  }, [subsiteContent, ready]);

  if (qaKey.startsWith('customer_help')) {
      return (
          <div id={qaKey} className={qaKey.startsWith(`${prefix}_q`) ? "customer-help-question" : "customer-help-answer"} data-qa-key={qaKey}>
              {subsiteContent}
          </div>
      );
  } else {
      return (
          <div id={qaKey} className={qaKey.startsWith(`${prefix}_q`) ? "service-subsite-question" : "service-subsite-answer"} data-qa-key={urlParts}>
              {subsiteContent}
          </div>
      );
  }
}