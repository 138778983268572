// Part of the header, contains the navigation links for the different services
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clickMethods } from "../navExit/navExit.js";
import "./homePageNav.css";

function HomePageNav({ activeMenu, setActiveMenu }) {

    const { t } = useTranslation();

    const handleMenuClick = (menuIndex) => {
        if (window.innerWidth <= 800) {
            if (activeMenu === menuIndex) {
                setActiveMenu(null)
            } else {
                setActiveMenu(menuIndex);
            }
        }
    };

    return (
        <>
            <div className="home-page-nav">
                <div className="dropdown-nav">
                <Link to="#" className="nav-link" onClick={() => handleMenuClick(1)}>{t('cloud_title')}</Link>
                    {(activeMenu === 1 || window.innerWidth > 800 ) && (
                    <div className="dropdown-nav-menu">
                        <Link to="/cloud/microsoft365" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('cloud/microsoft365.microsoft_title')}</Link>
                        <Link to="/cloud/migration" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('cloud/migration.migration_title')}</Link>
                        <Link to="/cloud/consulting" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('cloud/consulting.cloud_consulting_title')}</Link>
                    </div>
                    )}
                </div>
                <div className="dropdown-nav">
                <Link to="#" className="nav-link" onClick={() => handleMenuClick(2)}>{t('network_title')}</Link>
                    {(activeMenu === 2 || window.innerWidth > 800 ) && (
                    <div className="dropdown-nav-menu">
                        <Link to="/network/networking" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('network/networking.network_architecture_title')}</Link>
                        <Link to="/network/firewall" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('network/firewall.firewall_title')}</Link>
                        <Link to="/network/vpn" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('network/vpn.vpn_title')}</Link>
                    </div>
                    )}
                </div>
                <div className="dropdown-nav">
                <Link to="#" className="nav-link" onClick={() => handleMenuClick(3)}>{t('software_title')}</Link>
                    {(activeMenu === 3 || window.innerWidth > 800 ) && (
                    <div className="dropdown-nav-menu">
                        <Link to="/software/python" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('software/python.python_title')}</Link>
                        <Link to="/software/csharp" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('software/csharp.csharp_title')}</Link>
                        <Link to="/software/webdev" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('software/webdev.web_title')}</Link>
                    </div>
                    )}
                </div>
                <div className="dropdown-nav">
                <Link to="#" className="nav-link" onClick={() => handleMenuClick(4)}>{t('operations_title')}</Link>
                    {(activeMenu === 4 || window.innerWidth > 800 ) && (
                    <div className="dropdown-nav-menu">
                        <Link to="/operations/itops" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('operations/itops.it_ops_title')}</Link>
                        <Link to="/operations/hwvirt" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('operations/hwvirt.hw_virt_title')}</Link>
                        <Link to="/operations/monitoring" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('operations/monitoring.monitoring_title')}</Link>
                    </div>
                    )}
                </div>
                <div className="dropdown-nav">
                <Link to="#" className="nav-link" onClick={() => handleMenuClick(5)}>{t('automation_title')}</Link>
                    {(activeMenu === 5 || window.innerWidth > 800 ) && (
                    <div className="dropdown-nav-menu">
                        <Link to="/automation/scripting" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('automation/scripting.scripting_title')}</Link>
                        <Link to="/automation/rpa" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('automation/rpa.rpa_title')}</Link>
                        <Link to="/automation/iot" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('automation/iot.iot_title')}</Link>
                        <Link to="/automation/erp" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('automation/erp.erp_title')}</Link>
                    </div>
                    )}
                </div>
                <div className="dropdown-nav">
                <Link to="#" className="nav-link" onClick={() => handleMenuClick(6)}>{t('security_title')}</Link>
                    {(activeMenu === 6 || window.innerWidth > 800 ) && (
                    <div className="dropdown-nav-menu">
                        <Link to="/security/itsec" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('security/itsec.sec_consulting_title')}</Link>
                        <Link to="/security/audit" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('security/audit.audit_title')}</Link>
                        <Link to="/security/pentest" className="nav-link" onClick={() => clickMethods(setActiveMenu)}>{t('security/pentest.pen_test_title')}</Link>
                    </div>
                    )}
                </div>
            </div>
        </>
    );
    }

export default HomePageNav;