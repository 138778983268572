import { Subsite } from '../subsiteStructure.js';
import '../services.css'; // Import the CSSs

function WebDev() {
    const url = '/software/webdev';
    const prefix = 'web';

    return (
        <Subsite url={url} prefix={prefix} />
    )
}

export default WebDev;